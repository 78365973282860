<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <div class="top bgFFF paddingTB20 paddingLR20">
        <span class="top_name">所属运营商：</span>
        <el-select v-model="operationId" placeholder="请选择" @change="getCarType">
          <el-option
            :label="value.operationName"
            :value="value.operationId"
            :key="value.operationId"
            v-for="value in tenantList"
          >
          </el-option>
        </el-select>
      </div>

      <info-table
        class="bgFFF"
        v-if="searchShow"
        :num="1"
        :nameStyle="{ height: '200px', width: '100px' }"
        :valueStyle="{ height: '200px' }"
        :tableData="tableData"
      >
        <template v-slot:obeyType>
          <div class="contentWrapper">
            <div class="itemBox audit">
              <GeminiScrollbar>
                <div
                  class="typeItem"
                  :class="{ activeItem: activeKey == ind }"
                  v-for="(val, ind) in typeList"
                  @click="itemClick(ind, val)"
                  :key="ind"
                >
                  {{ val.inspectionTypeDesc }}
                </div>
              </GeminiScrollbar>
            </div>
            <div class="buttonBox">
              <div class="tipWord">当前设置内容为PDA中，稽查取证页面，违规类型的配置选项</div>
              <div>
                <el-button style="color: green" type="text" @click="dialogVisible = true">
                  [新增]
                </el-button>
              </div>
              <div>
                <el-button style="color: green" type="text" @click="deleteItem"> [删除] </el-button>
              </div>
            </div>
          </div>
        </template>
      </info-table>

      <el-dialog
        title="新增违规类型"
        :visible.sync="dialogVisible"
        width="30%"
        @closed="handleClose"
      >
        <el-form ref="form" :model="formInline" label-width="80px">
          <el-form-item
            :rules="{ required: true, message: '请输入违规类型' }"
            prop="typeVal"
            :label="$t('searchModule.Violation_type')"
          >
            <el-input
              v-model="formInline.typeVal"
              maxlength="10"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="dialog-footer" style="margin: 0 auto; text-align: center; width: 100%">
          <el-button type="primary" @click="insertType">确 定</el-button>
          <span style="display: inline-block; width: 10px"></span>
          <el-button @click="dialogVisible = false">取 消</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import infoTable from "@/components/infoTable/infoTable";
export default {
  name: "auditAndAudit",
  components: {
    infoTable,
  },
  data() {
    return {
      searchShow: false,
      operationId: "",
      tenantList: [],
      activeKey: null,
      typeVal: "",
      dialogVisible: false,
      formInline: {
        typeVal: "",
      },
      typeList: [
        {
          inspectionTypeCode: 0,
          inspectionTypeDesc: "",
          inspectionTypeId: 0,
        },
      ],
      tableData: [
        {
          name: "稽查取证",
          key: "obeyType",
          required: true,
        },
      ],
    };
  },
  methods: {
    // 选择车辆类型
    getCarType(val) {
      // console.log('选中值:' + val)
      if (!this.searchShow) {
        this.searchShow = true;
      }
      if (this.tableShow) {
        this.tableShow = false;
      }
      this.getList();
    },
    // 所属运营商下拉框
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    handleClose() {
      this.$refs.form && this.$refs.form.resetFields();
    },
    backClick() {
      // this.typeList = [];
    },
    insertType() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.$axios
            .post(`/acb/2.0/inspectionType/add`, {
              data: {
                desc: this.formInline.typeVal,
                operationId: this.operationId,
              },
            })
            .then((res) => {
              this.dialogVisible = false;
              this.getList();
            })
            .catch((e) => {
              this.dialogVisible = false;
            });
        } else {
          return false;
        }
      });
    },
    deleteItem() {
      if (this.activeKey === null) {
        this.$alert("未选择类型", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      this.$axios
        .post(`/acb/2.0/inspectionType/delete/${this.typeList[this.activeKey].inspectionTypeId}`)
        .then((res) => {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          this.getList();
        });
      this.activeKey = null;
    },
    itemClick(key, val) {
      this.activeKey = key;
    },
    getList() {
      this.$axios
        .get(`/acb/2.0/inspectionType/list`, {
          data: { operationId: this.operationId },
        })
        .then((res) => {
          this.typeList = res.value;
        });
    },
  },
  created() {
    this.getTenantList();
  },
  mounted() {},
  activated() {},
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
>>>.colName
  background #fff
.content {
  overflow: hidden;

  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;

    .export {
      font-size: 12px;
      border-radius: 4px;
      color: #0099FA;
      padding: 8px 13px;
      cursor: pointer;

      .iconfont {
        margin-right: 14px;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.upload-demo {
  display: inline-block;
}
</style>
<style scoped>
.top {
  width: 100%;
  margin: 12px auto 18px;
  font-size: 16px;
}

.top .top_name {
  margin: 0 15px 0 0;
}
.el-button {
  margin: 0 !important;
}
.contentWrapper {
  overflow: hidden;
}
.itemBox {
  width: 200px;
  height: 200px;
  border-right: 1px solid #eee;
  float: left;
  overflow: auto;
}
.buttonBox {
  float: left;
  padding-top: 50px;
  position: relative;
}
.buttonBox > div {
  padding-left: 20px;
}
.typeItem {
  padding: 0 4px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
}
.typeItem:hover {
  background: green;
  color: white;
}
.activeItem {
  background: green;
  color: white;
}
.tipWord {
  width: 500px;
  position: absolute;
  left: 100px;
  top: 80px;
  color: #777;
}
</style>
<style>
.audit .gm-scrollbar .thumb {
  background-color: #eee !important;
}
.audit .gm-scrollbar.-vertical {
  margin-right: -3px;
  margin-top: -2px;
  background-color: #fff !important;
}
</style>
